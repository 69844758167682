(function(window){
    function TimeTableFilterV2(container, params) {
        try {
            if (!container) {
                throw "Container is required!";
            }

            this.container = container;
            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    TimeTableFilterV2.prototype = {
        container: undefined,
        button: undefined,
        notFoundContent: undefined,
        newSearchBtn: undefined,
        table: undefined,
        tableBody: undefined,
        sendedForm: false,
        textField: undefined,
        dateField: undefined,
        form: undefined,
        type: 'indul',
        texts: {
            'ticket_button_txt': 'jegyvásárlás'
        },
        init: function() {
            this.setElements();
            this.addDOMEvents();
        },
        setElements: function() {
            this.form = this.container.find('[data-purpose="flight-search-box"]');
            this.button = this.container.find('[data-purpose="search-button"]');
            this.table = this.container.find('#flightsTable');
            this.tableBody = this.table.find('tbody');
            this.notFoundContent = this.container.find('[data-purpose="not-found-content"]');

            this.textField = this.container.find('[data-purpose="text-field"]');
            this.dateField = this.container.find('[data-purpose="date-field"]');

            if (this.notFoundContent) {
                this.newSearchBtn = this.notFoundContent.find('.link-with-icon');
            }
        },
        addDOMEvents: function() {
            var _self = this;

            this.form.on('submit', function(e){
                e.preventDefault();
                _self.sendForm();
            });

            if (this.button.length > 0) {
                this.button.click(function(){
                    _self.sendForm();
                });
            }

            if (this.textField.length == 1) {
                this.textField.on('keydown', function(e){
                    var keyCode = typeof e.keyCode !== 'undefined' ? e.keyCode : e.which;
                    if (keyCode && keyCode == 13) {
                        _self.sendForm();
                    }
                });
            }

            if (this.dateField.length > 0) {
                if (this.isMobileBrowser()) {
                    this.dateField.attr('type', 'date');
                    this.dateField.attr('min', '2019-07-26');

                    this.dateField.change(function(){
                        var value = $(this).val();
                        if (!value && $(this).hasClass('has-value')) {
                            $(this).removeClass('has-value');
                        } else if (value && !$(this).hasClass('has-value')) {
                            $(this).addClass('has-value');
                        }
                    });
                } else {
                    this.dateField.attr('readonly', true);
                    switch (frontend_lang) {
                        case 'hu':
                            $.datepicker.setDefaults($.datepicker.regional['hu']);
                            break;
                    }

                    this.dateField.datepicker({
                        appendTo: _self.dateField.parent(),
                        minDate:'today',
                        beforeShow: function(input, inst) {
                            inst.dpDiv.addClass('opened-datepicker');
                        },
                        onClose: function(input, inst) {
                            inst.dpDiv.removeClass('opened-datepicker');
                        }, 
                        onSelect: function(input, inst) {
                            if (input == inst.lastVal) {
                                inst.input.val('');
                                inst.lastVal = '';
                            }
                        }
                    });
                }
            }

            if (this.newSearchBtn && this.newSearchBtn.length > 0) {
                this.newSearchBtn.click(function(){
                    _self.textField.val('');
                    _self.dateField.val('');
                    _self.textField.focus();
                    _self.sendForm();
                });
            }
        },
        setParams: function(params) {
            if (params.type) this.setType(params.type);
            if (params.texts) this.setTexts(params.texts);
        },
        setType: function(type) {
            this.type = type;
        },
        setTexts: function(texts) {
            this.texts = $.extend({}, this.texts, texts);
        },
        getType: function() {
            return this.type;
        },
        getText: function(key) {
            return typeof this.texts[key] ? this.texts[key] : undefined;
        },
        sendForm: function() {
            if (!this.sendedForm) {
                var _self = this;

                this.sendedForm = true;

                var data = {
                    lang: frontend_lang,
                    type: this.getType(),
                };
                this.form.serializeArray().forEach(function(field){
                    data[field.name] = field.value;
                });

                $.ajax({
                    url:ajax_controller+'search_flights',
                    dataType:'json',
                    type:'post',
                    data:data,
                    success: function(response) {
                        if (typeof response.result === 'object') {
                            if (Array.isArray(response.result)) {
                                _self.handleSearchResponse(response.result);
                            }
                        }
                    },
                    complete: function() {
                        setTimeout(function(){
                            _self.sendedForm = false;
                        }, 500);
                    }
                });
            }
        },
        handleSearchResponse: function(flights) {
            if (flights.length > 0) {
                if (this.notFoundContent.is(':visible')) {
                    this.notFoundContent.hide();
                    this.table.show();
                }
                this.tableBody.html('');
                for (var i = 0; i < flights.length; i++) {
                    var row = $('<tr>');

                    row.append('<td class="airline-col">'+flights[i].airline+'</td>');
                    row.append('<td>'+flights[i].flightnumber+'</td>');
                    row.append('<td>'+flights[i].city+'</td>');
                    if (this.getType() == 'indul') {
                        row.append('<td class="check-in-col">'+flights[i].checkin+'</td>');
                    }

                    row.append('<td>'+flights[i].expect+'</td>');
                    var lastCol = $('<td>', {
                        class: this.getType() == 'indul' ? 'status-col' : ''
                    });

                    var lastInner = $('<span>', {
                        class:'inner',
                        html: '<span class="text">'+flights[i].status+'</span>'
                    });

                    if (this.getType() == 'indul' && typeof flights[i].bookingLink !== 'undefined' && flights[i].bookingLink !== '' && flights[i].bookingLink !== null &&flights[i].bookingLink !== 'javascript:void(0)') {
                        lastInner.append('<a href="'+flights[i].bookingLink+'" target="_blank" class="button booking-button with-text"><span class="text">'+this.getText('ticket_button_txt')+'</span><span class="text-mobil">'+svg('jegy-plane-icon')+'</span></a>');
                    }

                    lastCol.append(lastInner);
                    row.append(lastCol);
                    this.tableBody.append(row);
                }
            } else {
                this.table.hide();
                this.notFoundContent.show();
            }
        },
        isMobileBrowser: function() {
            var check = false;
            var check = false;
            (function(a) {
                if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4)))
                check = true;
                }
            )(navigator.userAgent || navigator.vendor || window.opera);
            return check;
        }
    };

    window.TimeTableFilter = TimeTableFilterV2;
})(window);
