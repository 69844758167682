(function(window){
    function TerminalMapV2(container, params) {
        try {
            if (!container) {
                throw 'Container is required!!!';
            }

            this.container = container;
            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    TerminalMapV2.prototype = {
        container: undefined,
        groundFloorContainer: undefined,
        firstFloorContainer: undefined,
        buttonPanel: undefined,
        zoomInButton: undefined,
        zoomOutButton: undefined,
        touchDevice: false,
        tabItems: undefined,
        options: {
            lang: 'hu'
        },
        currentFloor: 'ground-floor',
        panzoomObj: {},
        tracks: {
            'ground-floor':[],
            'floor-1':[]
        },
        init: function() {
            this.touchDevice = navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/);
            this.setElements();
            this.addDOMEvents();
        },
        setElements: function() {
            this.buttonPanel = this.container.find('[data-purpose="zoom-buttons-panel"]');
            this.zoomInButton = this.buttonPanel.find('[data-purpose="zoom-in-button"]');
            this.zoomOutButton = this.buttonPanel.find('[data-purpose="zoom-out-button"]');

            this.tabItems = this.container.find('[data-purpose="tab-header-item"]')

            this.groundFloorContainer = this.container.find('[data-purpose="ground-floor"]');
            if (this.groundFloorContainer && this.groundFloorContainer.length > 0) {
                this.loadMaps(this.groundFloorContainer, 'ground-floor', true);
            }

            this.firstFloorContainer = this.container.find('[data-purpose="floor-1"]');
            if (this.firstFloorContainer && this.firstFloorContainer.length > 0) {
                this.loadMaps(this.firstFloorContainer, 'floor-1');
            }
        },
        addDOMEvents: function() {
            var _self = this;

            this.zoomInButton.click(function(){
                _self.panzoomObj[_self.currentFloor].zoomIn();
            });

            this.zoomOutButton.click(function(){
                _self.panzoomObj[_self.currentFloor].zoomOut();
            });

            if (this.tabItems.length > 0) {
                this.tabItems.click(function(){
                    var floor = $(this).data('floor');
                    if (_self.currentFloor != floor) {
                        _self.tabItems.removeClass('active');
                        $(this).addClass('active');
                        _self.changeFloor(floor);
                    }
                });
            }
        },
        setParams: function(params) {
            if (params.options) {this.setOptions(params.options)};
        },
        setOptions: function(options) {            
            this.options = $.extend({}, this.options, options);
        },
        loadMaps: function(target, floor, initZoom) {
            var _self = this;
            
            var url = '/assets/img/svg/terminal-map-'+floor+'-'+this.getOption('lang')+'.svg';
            var xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.send();
            xhr.onload = function(e) {
                if (xhr.status == 200) {
                    target.html(xhr.responseText);
                    if (initZoom) {
                        _self.initPanZoom(floor);
                    }
                }
            }
        },
        initPanZoom: function(floor, viewBox) {
            var item = undefined;
            var key = undefined;
            
            
            var center = [];
            var zoomCount = 0;

            switch (floor) {
                case 0:
                case 'ground':
                case 'ground-floor':
                    item = this.groundFloorContainer;
                    key = 'ground-floor';
                    center = [3600, 2100];
                    zoomCount = Math.ceil(7138 / window.innerWidth);
                    
                    break;
                case 1:
                case 'floor-1':
                    item = this.firstFloorContainer;

                    key = 'floor-1';
                    center = [3600, 2100];
                    zoomCount = Math.ceil(7138 / window.innerWidth);
                    
                    break;
            }
            
            if (item) {
                this.currentPanItem = item;
                var elem = item.find('> svg');
                if (elem && elem.length) {
                    var _self = this;
                    var options = {
                        eventMagnet: document.querySelector('[data-purpose="'+floor+'"]'),
                        zoom: {
                            minZoom:1,
                            maxZoom:20,
                            events: {
                                mouseWheel: false,
                            }
                        },
                    };

                    this.panzoomObj[floor] = new SVGPanZoom(document.querySelector('[data-purpose="'+floor+'"] > svg'), options);

                    if (center && center.length == 2) {
                        this.panzoomObj[floor].setCenter(center[0], center[1]);
                    }

                    zoomCount = zoomCount && zoomCount > 7 ? 7 : zoomCount;
                    for (var i = 0; i < zoomCount; i++) {
                        this.panzoomObj[floor].zoomIn({x:3600, y:2100});
                    }
                }                
            }
        },
        changeFloor: function(floor) {
            var viewBox = undefined;
            if (this.panzoomObj[this.currentFloor] !== 'undefined') {
                viewBox = this.panzoomObj[this.currentFloor].getViewBox();
            }

            this.currentFloor = floor;
            if (typeof this.panzoomObj[floor] == 'undefined') {
                this.initPanZoom(floor, viewBox);
            } else if (viewBox) {
                this.panzoomObj[this.currentFloor].setViewBox(viewBox.x, viewBox.y, viewBox.width, viewBox.height, 0);
            }

            switch (floor) {
                case 'ground-floor':
                    this.firstFloorContainer.removeClass('active');
                    this.groundFloorContainer.addClass('active');
                    break;
                case 'floor-1':
                    this.groundFloorContainer.removeClass('active');
                    this.firstFloorContainer.addClass('active');
                    break;
            }
        },
        getOption: function(key) {
            return typeof this.options[key] !== 'undefined' ? this.options[key] : undefined;
        }
    };

    window.zoomableTerminalMap = TerminalMapV2;
})(window);
