(function(window){
    function DestinationSlider (container, params) {
        try {
            if (!container || (container && container.length == 0)) {
                throw 'Container is required!';
            }

            this.container = container;
            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (e) {
            console.error(e);
        }
    }

    DestinationSlider.prototype = {
        container: undefined,
        sliderContent: undefined,
        sliderContentOuter: undefined,
        pagerNext: undefined,
        pagerPrev: undefined,
        breakpoint: 768,
        currentPage: 0,
        containerWidth: 0,
        sliderWidth: 0,
        init: function() {
            this.setElements();
            this.setContainerWidth();
            this.setSliderContentWidth();
            
            this.addDOMEvents();
            this.slide(0);
        },
        setElements: function() {
            this.pagerNext = this.container.find('[data-purpose="next-pager"]');
            this.pagerPrev = this.container.find('[data-purpose="prev-pager"]');
            this.sliderContent = this.container.find('[data-purpose="slider-content"]');
            
            if (this.sliderContent.length == 0) {
                throw 'Slider content is required!';
            }

            this.sliderContentOuter = this.sliderContent.parent();
        },
        addDOMEvents: function() {
            var _self = this;

            if (this.pagerNext.length > 0) {
                this.pagerNext.click(function(){
                    _self.slide(-1);
                });
            }

            if (this.pagerPrev.length > 0) {
                this.pagerPrev.click(function(){
                    _self.slide(1);
                });
            }

            $(document).on('airport.window_width_changed', function(){
                _self.setContainerWidth();
                _self.setSliderContentWidth();
            });
        },
        setParams: function(params) {

        },
        slide: function(direction) {
            if (!direction) {
                direction = 0;
            }

            var innerWidth = this.getContainerWidth();
            var boxWidth = this.getBoxWidth();
            var stepSize = Math.floor(innerWidth / (boxWidth - 13)) * (boxWidth + 13);
            var currentSlide = parseInt(this.sliderContent.data('current-slide'));
            var contentWidth = this.getSliderContentWidth();

            if (isNaN(currentSlide)) {
                currentSlide = 0;
            }
            
            currentSlide += (direction * stepSize);
            
            if (currentSlide >= 0) {
                currentSlide = 0;
                if (this.pagerPrev.hasClass('active')) {
                    this.pagerPrev.removeClass('active');
                } 
            }
            
            
            if (contentWidth < Math.abs(currentSlide) + innerWidth) {
                currentSlide = -1 * contentWidth + innerWidth;
                if (this.pagerNext.hasClass('active')) {
                    this.pagerNext.removeClass('active');
                }
            } else {
                if (!this.pagerNext.hasClass('active')) {
                    this.pagerNext.addClass('active');
                }
            }

            if (currentSlide < 0) {
                if (!this.pagerPrev.hasClass('active')) {
                    this.pagerPrev.addClass('active');
                }
            }

            if (windowWidth >= this.breakpoint) {
                this.sliderContent.css({
                    'transform': 'translateX('+ currentSlide +'px)',
                    'width':contentWidth+'px'
                });
            } else {
                this.sliderContent.css({
                    'transform': 'translateX(0px)',
                });
            }
            
            this.sliderContent.data('current-slide', currentSlide);
        },
        setSliderContentWidth: function() {
            var width = 0;

            this.sliderContent.children().each(function(){
                width += $(this).outerWidth(true);
            });

            this.sliderContent.width(width);

            this.sliderWidth = width;
        },
        getSliderContentWidth: function() {
            return this.sliderWidth;
        },
        setContainerWidth: function() {
            this.containerWidth = this.sliderContentOuter.outerWidth();
        },
        getContainerWidth: function() {
            return this.containerWidth;
        },
        getBoxWidth: function() {
            return 250;
        }
    };

    window.DestinationSlider = DestinationSlider;
})(window);
