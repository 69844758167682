window.loaded = false;
var windowWidth = 0;
var headerSlider = undefined;
var mobileHeaderSlider = undefined;
var mainMenuItemWithSubmenu = undefined;
var mainMenuContent = undefined;
var tabHeaderItems = undefined;
var flightBoxContents = undefined;

$(window).on('load', function(){
	window.loaded = true;

	get_weather();
	loadBookingContent();
});

var headerBorder = undefined;
var fixMenuOuter = undefined;
var notificationOuter = undefined;
$(document).ready(function() {
	/* MOBILE */
	//create js hook links
	windowWidth = window.innerWidth;
	mainMenuItemWithSubmenu = $('.main-menu-item.set-arrow-dynamic');
	mainMenuContent = $('[data-purpose="main-menu-outer"]');
	notificationOuter = $('[data-purpose="notification-outer"]');
	

	if (notificationOuter.length > 0) {
		notificationOuter.find('.close-btn').click(function(){
			closeNotificationContent();
		});
	}

	$('.mailtolink').click(function(e) {
		handleMailtoLink($(this));
	});
	
	var resizeFn = function(){
		if (windowWidth != $(window).width()){
			windowWidth = $(window).width();

			$(document).trigger('airport.window_width_changed');
			positionSubmenuArrows();
		}
	}
	
	positionSubmenuArrows();

	window.addEventListener('resize', resizeFn);
	
	$('[data-purpose="remodal-open"]').click(function(){
		var target = $(this).data('popup-target');
		if ($(this).data('popup-target')) {
			var item = $('[data-remodal-id="' + $(this).data('popup-target') + '"]');
			if (item.length > 0) {
				item.remodal().open();
			}
		}
	});
	
	if( $('.cookie-bar').length > 0 ) {

		$('.cookie-bar .button').click(function(){
			$('.cookie-bar').addClass('closed');

			$.ajax({
				url:ajax_controller+'setCookieConfirm',
				dataType:'json',
				type:'post',
				data:{cookie:1},
				success: function(){}
			});
		});
	}

	$('[data-purpose="remodal-close"]').click(function(){
		var value = $(this).data('value');
		if (value && value == 'confirm') {
			$('#terms-chk').attr('checked', true);
		}

		$(this).parents('.remodal').remodal().close();
	});

	$('[data-purpose="scroll-to-element"]').click(function(){
		var target = $(this).data('target');
		var item = $(target);
		
		if (item.length > 0) {
			$('html, body').animate({scrollTop: item.offset().top - $('.main-header .top').height()}, 400);
		}
	});

	$('[data-purpose="remodal-open"]').click(function(){
		var target = $(this).data('target');
		if (target) {
			openRemodalContent(target);
		}
	});

	$('[data-purpose="privacy-confirm-button"]').click(function(){
		var type = $(this).data('type');
		$('#privacyChk').prop('checked', type == 'accept');
		$('[data-remodal-id="privacyRemodal"]').remodal().close();
	});

	fixMenuOuter = $('[data-purpose="floating-menu"]');
	headerBorder = $('[data-purpose="header-scroll-border"]');
	if (headerBorder.length == 0) {
		headerBorder = $('.main-header');
	}

	var scrollFn = function() {
		var height = headerBorder.outerHeight(true) + headerBorder.offset().top;
		var scrollTop = $(window).scrollTop()
		
		if (height <= scrollTop && !fixMenuOuter.hasClass('active')) {
			fixMenuOuter.addClass('active')
		} else if (height > scrollTop && fixMenuOuter.hasClass('active')) {
			fixMenuOuter.removeClass('active')
		}
	};

	window.addEventListener('touchend', scrollFn);
	window.addEventListener('touchmove', scrollFn);
	window.addEventListener('scroll', scrollFn);

	if (typeof site_id !== 'undefined') {
		if (site_id == 1) {
			tabHeaderItems = $('[data-purpose="flight-box-tab"]');
			flightBoxContents = $('[data-purpose="flight-box-content"]');

			if (tabHeaderItems.length > 0) {
				tabHeaderItems.click(function(){
					if (!$(this).hasClass('selected')) {
						tabHeaderItems.removeClass('selected');
						$(this).addClass('selected');

						if (flightBoxContents.length > 0) {
							flightBoxContents.removeClass('selected');
							var type = $(this).data('type');
							flightBoxContents.each(function(){
								if ($(this).data('type') == type) {
									$(this).addClass('selected')
								}
							});
						}
					}
				});
			}

		} 
	}

	$('.accordion-list').each(function(ind, el) {

		var options = {};
		var th = $(el);

		if (th.data('single-active-item') != undefined) options.singleActiveItem = true;
		if (th.data('active-index') != undefined) options.activeIndex = th.data('active-index');
		if (th.data('from-width') != undefined) options.fromWidth = parseInt(th.data('from-width'));
		
		new Accordion(th, options);
	});

	$('select').change(function(){
		var val = $(this).val();
		var parent = $(this).parent();
		if (!val && !parent.hasClass("without-value")) {
			parent.addClass("without-value");
		} else if (val && parent.hasClass("without-value")) {
			parent.removeClass("without-value")
		}
	}).change();

	$('[data-purpose="lang-chooser"]').change(function(){
		changeFrontendLang($(this));
	});
});


function openRemodalContent(remodal_id) {
	var tmp = $('[data-remodal-id="'+remodal_id+'"]');
	if(tmp.length > 0){
	  tmp.remodal().open();
	}
}

function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

function sendAnalyticsEvent(action, category, tag) {
	if (typeof gtag !== 'undefined' && action && category) {
		var evtData = {
			event_category: category,
		};

		if (tag && tag.trim() != '') {
			evtData.event_label = tag;
		}
		
		gtag('event', action, evtData);
	}
	else if(typeof ga != 'undefined' && action && category){
		if(tag && tag.trim() != ''){
			ga('send', 'event', action, category, tag);
		}else{
			ga('send', 'event', action, category);
		}
	}
}


function svg(id) {
	return '<span class="svg"><svg class="icon ' + id + '"><use xlink:href="#' + id + '" /></svg></span>';
}


function handleMailtoLink(th) {
	if (th.attr('href') == 'javascript:void(0)') {
		var hidden = th.children('.emailhidden');
		th.attr('href', 'mailto:' + hidden.data('user') + '@' + hidden.data('domain'));
	}
}


function loadJs(url, implementationCode){
    var scriptTag = document.createElement('script');
    scriptTag.src = url;

    scriptTag.onload = implementationCode;
    scriptTag.onreadystatechange = implementationCode;

    document.body.appendChild(scriptTag);
};

function setNavigationUrl(item) {
	var address = item.data('address');
	
	if (address) {
		var protocol = 'google';
		var ua = navigator.userAgent.toLowerCase();
		var plat = navigator.platform;
		var device = ua.match(/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera/i) ? ua.match(/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera/i)[0] : false;

		if(device == 'iphone' || device == 'ipad' || device == 'ipod'){
			function iOSversion() {
					
				if (/iP(hone|od|ad)/.test(navigator.platform)) {
				  var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
				  return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
				}
			}

			var ver = iOSversion() || [0];
			if (ver[0] >= 6) {
				protocol = 'apple';
			}
		}
		
		var urlSuffix = '/?daddr='+address;
		var nav_url = '';
		switch(protocol) {
			case 'apple':
				nav_url = 'maps://';
				break;
			default:
				nav_url = 'https://maps.google.com/maps';
				break;
		}
		item.attr('target', '_blank');
		item.attr('href', nav_url+urlSuffix);
	}
}

function get_weather()
{
	$.ajax({
		url:ajax_controller+'get_weather',
		dataType:'json',
		type:'post',
		success: function(response) {
			if (response.success) {
				var box = $('[data-purpose="weather-box"]');
				if (box.length > 0) {
					var container = box.find('[data-purpose="value"]');
					if (container.length > 0) {
						container.html(response.weather);
						box.css('opacity', 1);
					}
				}
			}
		}
	});
}

function positionSubmenuArrows() {
	if (mainMenuItemWithSubmenu.length > 0) {
		mainMenuItemWithSubmenu.each(function(){
			var itemWidth = $(this).width();
			
			var left = $(this).position().left;
			
			$(this).find('[data-purpose="arrow-left-side"]').width(left+itemWidth / 2+31);
		});
	}
}

function closeNotificationContent() {
	notificationOuter.slideUp(200);
	var id = notificationOuter.data('id');
	
	if (id) {
		$.ajax({
			url:ajax_controller+'close_notification',
			dataType:'json',
			type:'post',
			data: {
				notification_id: id
			}
		});
	}
}


function changeFrontendLang(select) {
	var option = select.find(':selected');
	if (option && option.length == 1) {
		var url = option.data('url');
		if (url.trim() !== '') {
			document.location.href = url;
		}
	}
	
}

function loadBookingContent()
{
	$('[data-purpose="booking-frame-section"]').each(function(){
		var type = $(this).data('type');
		if (type) {
			var frame = $('<iframe>', {
				src: base_url+'booking-frame/'+type+'/'+frontend_lang,
				class: 'booking-frame '+type+'-frame content-width hidden'
			});
			
			$(this).append(frame);
			frame.on('load', function() {
				$(this).removeClass('hidden');
			});
		} else {
			$(this).hide();
		}
	});
}
