(function(window){
    function HomeCitySlider(container, params) {
        try {
            if (!container) {
                throw 'Container is required!'
            }

            this.container = container;
            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    HomeCitySlider.prototype = {
        container: undefined,
        scrollContent: undefined,
        memberBoxes: undefined,
        textContent: undefined,
        membersContainer: undefined,
        pagerNext: undefined,
        pagerPrev: undefined,
        topRow: undefined,
        bottomRow: undefined,
        init: function() {
            this.setElements();
            this.setBoxSizes();

            this.addDOMEvents();
            
        },
        setElements: function() {
            this.scrollContent = this.container.find('[data-purpose="scrollable-content"]');
            this.textContent = this.container.find('[data-purpose="text-content"]');
            this.memberBoxes = this.container.find('[data-purpose="members-container"]');
            this.membersContainer = this.container.find('[data-purpose="members-col"]');
            this.pagerNext = this.container.find('[data-purpose="next-pager"]');
            this.pagerPrev = this.container.find('[data-purpose="prev-pager"]');
            this.topRow = this.container.find('[data-purpose="members-row"][data-type="top"]');
            this.bottomRow = this.container.find('[data-purpose="members-row"][data-type="bottom"]');
        },
        addDOMEvents: function() {
            var _self = this;
            
            this.pagerNext.find('.arrow-button').click(function(){
                _self.slide(-1);
            });

            this.pagerPrev.find('.arrow-button').click(function(){
                _self.slide(1);
            });

            var resizeFn = function() {
                _self.setBoxSizes();
            }

            window.addEventListener('resize', resizeFn);
        },
        setParams: function(params) {
            console.log(params);
        },
        setBoxSizes: function() {
            var width = this.getInnerSize();

            if (width > windowWidth) {
                this.pagerNext.addClass('active');
            }
            if (windowWidth >= 601) {
                this.scrollContent.width(width);
            } else {
                this.scrollContent.removeAttr('style');
            }
                
            
        },
        slide: function(direction) {
            var width = this.getInnerSize();
            var windowWidth = $(window).width();
            var currentSlide = parseInt(this.scrollContent.data('slide-value'));
                        
            currentSlide += (direction * windowWidth);
            if (currentSlide >= 0) {
                currentSlide = 0;
                if (this.pagerPrev.hasClass('active')) {
                    this.pagerPrev.removeClass('active');
                }
            }

            if (Math.abs(currentSlide) > (width - windowWidth)) {
                currentSlide = (width - windowWidth) * -1;
                if (this.pagerNext.hasClass('active')) {
                    this.pagerNext.removeClass('active');
                }
            } else {
                if (!this.pagerNext.hasClass('active')) {
                    this.pagerNext.addClass('active');
                }
            }

            if (currentSlide < 0) {
                this.pagerPrev.addClass('active');
            }
            
            if (windowWidth >= 1024) {
                this.scrollContent.css({
                    'transform': 'translateX('+currentSlide+'px)'
                });
            } else {
                this.scrollContent.css({
                    'transform':'translateX(0px);'
                });
            }
            this.scrollContent.data('slide-value', currentSlide);
        },
        getInnerSize: function() {
            var width = this.textContent.outerWidth(true);
            var membersWidth = 0;
            
            var topRowWidth = this.getRowWidth(this.topRow) + 74;
            var bottomRowWidth = this.getRowWidth(this.bottomRow);

            membersWidth = topRowWidth > bottomRowWidth ? topRowWidth : bottomRowWidth;
            
            this.membersContainer.find('> .inner').width(membersWidth);
            width += membersWidth;

            return width;
        },
        getBoxWidth: function(box) {
            if (box) {
                if (box.hasClass('small-item')) {
                    return 221;
                } else if (box.hasClass('medium-item')) {
                    return 250;
                }
            }

            return 295;
        },
        getRowWidth: function(row) {
            var _self = this;
            var width = 0;
            if (row) {
                var children = row.children();
                children.each(function(){
                    width += _self.getBoxWidth($(this));
                });

                width += (children.length) * 30;
            }

            return width;
        }
    };

    window.HomeCitySlider = HomeCitySlider;
})(window);
