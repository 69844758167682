(function(wnd) {
    function SearchPage(container, params) {
        try {
            if (!container) {
                throw 'Container is required!';
            }

            this.container = container;

            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    SearchPage.prototype = {
        container: undefined,
        listContainer: undefined,
        buttonContainer: undefined,
        button: undefined,
        results: [],
        perPage: 4,
        currentPage: 0,
        searchParam: '',
        textLimit: 170,
        texts: {
            button_text: 'további %COUNTER% találat'
        },
        init: function() {
            this.setElements();
            this.addDOMEvents();
            this.loadResults(this.currentPage);
        },
        setElements: function() {
            this.listContainer = this.container.find('[data-purpose="result-list"]');
            this.buttonContainer = this.container.find('[data-purpose="more-button-row"]');
            this.button = this.buttonContainer.find('.button');

            if (this.results.length <= this.perPage) {
                this.buttonContainer.hide();
            }
        },
        addDOMEvents: function() {
            var _self = this;

            this.button.click(function(){
                _self.currentPage++;
                _self.loadResults(_self.currentPage);
            });
        },
        setParams: function(params) {
            if (params.results) this.setResults(params.results);
            if (params.texts) this.setTexts(params.texts);
            if (params.searchParam) this.setSearchParam(params.searchParam);
            if (params.textLimit) this.setTextLimit(params.textLimit);
        },
        setResults: function(results) {
            this.results = results;
        },
        loadResults: function(page) {
            var start = page * this.perPage;
            var end = start + this.perPage;
            
            var moreCounter = end + this.perPage < this.results.length ? this.perPage : (this.results.length - end);

            for (var i = start; i < end; i++) {
                if (typeof this.results[i] !== 'undefined') {
                    this.writeResult(this.results[i], i);
                } 
            }
            console.log(moreCounter);
            if (end >= this.results.length) {
                this.buttonContainer.hide();
            } else {
                var str = this.getText('button_text');
                str = str.replace('%COUNTER%', moreCounter);
                this.button.find('.text').html(str);
            }
        },
        writeResult: function(result, index) {
            var content = $('<a>', {
                'href': typeof result.url !== 'undefined' ? result.url : 'javascript:void(0)',
                'class': 'search-item',
                'id': 'search-item-'+index
            });

            if (typeof result.title !== 'undefined' && result.title) {
                content.append('<span class="title-row">'+result.title+'</span>');
            }

            if (typeof result.text !== 'undefined' && result.text) {
                var text = this.getHighlightedText(result.text);
                content.append('<span class="text-content">'+text+'</span>');
            }

            this.listContainer.append(content);
        },
        setTexts: function(texts) {
            this.texts = $.extend({}, this.texts, texts);
        },
        getText: function(key) {
            return typeof this.texts[key] !== 'undefined' ? this.texts[key] : key;
        },
        setSearchParam: function(param) {
            this.searchParam = param;
        },
        getSearchParam: function() {
            return this.searchParam;
        },
        setTextLimit: function(limit) {
            this.textLimit = parseInt(limit);
        },
        getTextLimit: function() {
            return this.textLimit;
        },
        getHighlightedText: function(text) {
            var str = text;
            var param = this.getSearchParam();
            if (text.length > this.getTextLimit()) {
                var index = (text.toLowerCase()).indexOf(param.toLowerCase());
                
                if (index <= this.getTextLimit()) {
                    str = text.substring(0, this.getTextLimit())+'...';
                } else {
                    var end = (text.length - index) > this.getTextLimit() ? index + this.getTextLimit() : text.length;
                    var suffix = '';
                    if ((text.length - index) > this.getTextLimit()) {
                        end = index + this.getTextLimit() - 80;
                        suffix = "...";
                    }

                    str = "..."+text.substring(index-80, end)+suffix;
                }
            }

            var terms = param.replace(/\[|\]|\\|\/|\(|\)|\.|\+|\*|\?/gi, '').split(' ');
            var term = terms.filter(function(term){ return term.length > 0 }).join('|');
            var reg = new RegExp('('+term+')', 'gi');
            
            var matches = str.match(reg);
            if (matches && matches.length > 0) {
                str = str.replace(reg, '<strong>$1</strong>');
            }
            
            return str;
        },
        
    };

    wnd.SearchPage = SearchPage;
})(window);
